import React from "react";
import { Link } from "gatsby";
import "./Outcome.scss";
import Layout from "../components/Layout";
import Button from "../components/Button";

// Path
const step04Path = "/Step04";
const homePath = "/";

const OutcomePage = () => {
  return (
    <Layout
      step="complete"
      returnHref={step04Path}
      nextHref={homePath}
      returnDataColor="border"
      nextDataColor="border"
      dataCheck01="done"
      dataCheck02="done"
      dataCheck03="done"
      dataCheck04="done"
    >
      <div className="outcome-heading">
        <h2>シミュレーション結果</h2>
      </div>
      <div className="outcome-choice">
        <ul>
          <li data-type="question">デスクの種類</li>
          <li data-type="answer">ハイデスク</li>
          <li data-type="price">¥ 5,000</li>
        </ul>
        <ul>
          <li data-type="question">天板の形</li>
          <li data-type="answer">I字天板</li>
          <li data-type="price">¥ 2,000</li>
        </ul>
        <ul>
          <li data-type="question">デスク脚</li>
          <li data-type="answer">４本脚</li>
          <li data-type="price">¥ 2,000</li>
        </ul>
        <ul>
          <li data-type="question">天板カラー</li>
          <li data-type="answer">ホワイト</li>
          <li data-type="price">¥ 1,000</li>
        </ul>
        <ul>
          <li data-type="sum">合計</li>
          <li data-type="price">¥ 10,000</li>
        </ul>
        <p>※デモサイトなので回答内容は固定です。</p>
      </div>
      <div className="outcome-add">
        <p>追加したい事項、要望などありましたらご自由にご記入ください。</p>
        <textarea placeholder="自由記入欄"></textarea>
        <div className="outcome-add-btn">
          <Button href={homePath} dataColor="secondary" dataForm="rounded">
            <span>最初からやり直す</span>
          </Button>
          <Button href={homePath} dataColor="primary" dataForm="rounded">
            <span>問い合わせに進む</span>
          </Button>
        </div>
        <p>※デモサイトなので情報は送信されません。</p>
      </div>
      <div className="outcome-other">
        <p>
          ２通りの方法でシミュレーションすることが出来ます。
          <br />
          お客様にあった方法でシミュレーションしてください。
        </p>
        <Link to={homePath}>予算から選ぶ</Link>
        <Link to={homePath}>目的から選ぶ</Link>
      </div>
    </Layout>
  );
};

export default OutcomePage;
